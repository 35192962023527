<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <h2 class="font-medium-3">
          Bảng tin
        </h2>
      </b-col>

    </b-row>

  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
  },
  setup() {

  },
  data() {
    return {
      apartment: null,
    };
  },
};
</script>
